<template>
	<div class="layout-topbar" :style="[{'background-color': isHomolog? '#574c9a' : ''}]">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span :style="textoCor">GestorWEB</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<h3 class="my-auto mx-auto" v-if="isHomolog"><b>Homologação</b></h3>
		<ul class="layout-topbar-menu hidden lg:flex origin-top" v-if="user">
			<li v-if="user.isClient">
				<Button
					type="button"
					:label="user.entidade_selecionada.CODENT + ' - ' + user.entidade_selecionada.NOMENT"
					class="p-button-secondary p-button-text mr-2 mb-2" icon="pi pi-angle-down"
					@click="entidadesDialog = true"
					style="width: auto;"
					v-if="user.isClient && user.entidade"
					 />
			</li>
			<li v-else>
				<Button
					type="button"
					:label="empresaLabel"
					class="p-button-secondary p-button-text mr-2 mb-2" icon="pi pi-angle-down"
					@click="empresasDialog = true"
					style="width: auto;"
					 />
			</li>
			<li>
				<Menu ref="settingsProfile" :model="settingsItems" :popup="true" @click="profileMenuSelected" />
				<button class="p-link layout-topbar-button" @click="toggleSettingsMenu">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<Menu ref="menuProfile" :model="profileItems" :popup="true" @click="profileMenuSelected" />
				<button class="p-link layout-topbar-button" @click="toggleProfileMenu">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul>
	</div>
	<Dialog v-model:visible="loggoutDialog" :style="{width: '450px'}" header="Sair" :modal="true">
    <div class="flex align-items-center justify-content-center">
      Deseja realmente sair e fechar sua sessão?
    </div>
    <template #footer>
      <Button label="Não" icon="pi pi-times" class="p-button-text" @click="loggoutDialog = false"/>
      <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="logout" />
    </template>
  </Dialog>
	<Dialog v-model:visible="empresasDialog" :style="{width: '50rem'}" header="Mudar Empresa" :modal="true">
		<div class="p-fluid">
			<div class="formgrid grid" v-if="user">
				<div class="field col-12 sm:col-12">
					<InputText placeholder="Procurar..." v-model="srcEmpresa" @input="filterEmpresas" />
				</div>
				<div class="col-12 sm:col-12" v-for="(entry, index) in empresas" v-bind:key="index">
					<RadioButton v-model="empresa" :value="entry.CODEMP" @change="empresaSelected" />
					<label for="ingredient1" class="ml-2">{{ entry.NOMEMP }}</label>
					<Divider />
				</div>				
			</div>
		</div>
		<template #footer>
			<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="empresasDialog = false"/>
		</template>
	</Dialog>
	<Dialog v-model:visible="entidadesDialog" :style="{width: '50rem'}" header="Mudar Entidade" :modal="true">
		<div class="p-fluid">
			<div class="formgrid grid" v-if="user">
				<div class="field col-12 sm:col-12">
					<InputText placeholder="Procurar..." v-model="srcEntidade" @input="filterEntidades" />
				</div>
				<div class="col-12 sm:col-12" v-for="(entry, index) in entidades" v-bind:key="index">
					<RadioButton v-model="entidade" :value="entry.CODENT" @change="mudarEntidade" />
					<label for="ingredient1" class="ml-2">{{ entry.NOMENT }}</label>
					<Divider />
				</div>				
			</div>
		</div>
		<template #footer>
			<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="entidadesDialog = false"/>
			<Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="mudarEntidade" />
		</template>
	</Dialog>
</template>

<script>
import EmpresaService from './service/EmpresaService';
import LoginService from './service/LoginService';
import ParametroService from './service/geral/ParametroService';
import UsuarioService from './service/administracao/UsuarioService';

import EventBus from './AppEventBus';
import LoginMixin from './mixins/Login';

export default {
		mixins : [ LoginMixin ],
		data() {
			return {
				isHomolog: process.env.VUE_APP_HOMOLOG,
				empresaService : null,
				parametroService : null,
				parametros : null,
				loggoutDialog:false,
				entidadesDialog:false,
				empresasDialog:false,
				entidades : [],
				entidadesOriginal : [],
				empresas : [],
				empresasOriginal : [],
				user : null,
				entidade : null,
				srcEntidade : null,
				empresa : null,
				srcEmpresa : null,
				empresaLabel : "Empresa",
				toolbarItems: [
					{
						label: 'Save',
						icon: 'pi pi-check'
					},
					{
						label: 'Update',
						icon: 'pi pi-upload'
					},
					{
						label: 'Delete',
						icon: 'pi pi-trash'
					},
					{
						label: 'Home Page',
						icon: 'pi pi-home'
					},
				],
				settingsItems : [
					{
						label: 'Tema',
						icon: 'pi pi-pallete',
						to : '/theme'
					},
				],
				profileItems: [
					{
					 	label: 'Perfil',
					 	icon: 'pi pi-user',
					 	to : '/profile'
					},					
					{
						label: 'Sair',
						icon: 'pi pi-power-off'
					},
				],

				textoCor: "color: ",
				tolbarCor: "background-color: ",
			};
		},
		beforeCreate() {
			EventBus.on('user-logged', (data) => {
				this.user = this.$root.user = data.user;

				this.loadUser()
				this.getEmpresas();
			});
		},
		created() {
			this.empresaService = new EmpresaService();
			this.parametroService = new ParametroService();

			this.empresaService = new EmpresaService();
		},

		async mounted() {
			this.user = this.$root.user;

			if (this.user?.isClient) {
				if (localStorage.getItem('termo_assinado') == "1") {
					this.profileItems.push(
						{
							label: 'Termo de adesão',
							icon: 'pi pi-star',
							target : '_blank',
							to : "/termos-adesao"
						},
					);
				}
			}
		},

    methods: {
			onMenuToggle(event) {
					this.$emit('menu-toggle', event);
			},
			onTopbarMenuToggle(event) {
				this.$emit('topbar-menu-toggle', event);
			},
			topbarImage() {
				return this.$appState.darkTheme ? 'images/blockchain.png' : 'images/blockchain.png';
			},
			getEmpresas() {
				this.empresaService
				.all()
				.then(
					(empresas) => {
						console.log("getEmpresas")
						this.empresasOriginal = this.empresas = empresas;

						const _items = [];

						empresas.map(
							(empresa) => _items.push({ label : empresa.NOMEMP, id : empresa.CODEMP })
						);

						this.toolbarItems = _items;

						(new EmpresaService()).getDefault()
						.then((response) => this.setEmpresa(response[0]));
					}
				)
			},
			empresaSelected(ev) {
				this.empresaService.change(this.empresa);
				this.setEmpresa(this.empresa);

				setTimeout(() => {
					location.reload();
				}, 1000);

				this.empresasDialog = false;
			},
			profileMenuSelected(ev) {
				switch (ev.target.textContent.toString().toLowerCase()) {
					case "sair" :
						this.loggoutDialog = true;
						break;
				}
			},

			toggleProfileMenu(event) {
				this.$refs.menuProfile.toggle(event);
			},
			toggleSettingsMenu(event) {
				this.$refs.settingsProfile.toggle(event);
			},
			searchEmpresa(text) {
				let id;
				this.toolbarItems.map(
					(item) => {
						if (item.label == text) {
							id = item.id;
						}
					}
				);

				return id;
			},
			setEmpresa(id) {
				this.empresa = id;

				this.toolbarItems.map(
					(item) => {
						if (parseInt(item.id) == parseInt(id)) {
							this.empresaLabel = item.label;
						}
					}
				);
			},
			logout() {
				this.loggoutDialog = false;
				(new LoginService()).logout()
				.then(() => {
					if (this.$auth0?.isAuthenticated) {
						this.$auth0.logout({
							openUrl : false
						})
						.then(resp => {
							this.$router.push({ path : "/login" });
						});
					}
					else {
						this.$router.push({ path : "/login" });
					}
				});
			},

			loadUser() {
				if (this.user) {
					if (this.user.isClient) {
						this.entidades.push({
							CODENT : this.user.entidade.CODENT,
							NOMENT : this.user.entidade.NOMENT,
						});

						if (this.user.entidades) {
							this.user.entidades.map(
								entry => {
									if (entry.CODENT == this.user.entidade.CODENT) return;

									this.entidades.push(entry)
								}
							)
						}

						this.entidadesOriginal = this.entidades;

						this.entidade = this.user.codent;
					}
				}
			},
			mudarEntidade() {
				this.entidadesDialog = false;

				console.log('mudarEntidade');

				(new UsuarioService())
				.changeEntidade({
					CodEnt : this.entidade
				})
				.then(
					response => {
						if (this.user.isClient) {
							if (!response.assinado && localStorage.getItem("has_termo") == '1') {
								this.$router.push({ path : "/termo-adesao"});
								return;
							}
						}

						this.loginCheck()
						.then(
							r => {
								this.user = this.$root.user;
								window.location.reload()
							}
						)
					}
				)
			},

			filterEntidades() {
				this.entidades = this.entidadesOriginal.filter(
					entry => entry.NOMENT.toLowerCase().indexOf(this.srcEntidade.toLowerCase()) > -1
				);
			},

			filterEmpresas() {
				this.empresas = this.empresasOriginal.filter(
					entry => entry.NOMEMP.toLowerCase().indexOf(this.srcEmpresa.toLowerCase()) > -1
				);
			},
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>